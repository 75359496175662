import { cn, cva } from "@sourceful/design-system-v3";

const styles = cva(
  "transition-all absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 clip-path-header-container",
  {
    variants: {
      blur: {
        true: "!blur-subtle",
      },
    },
  }
);

export interface HomeHeaderImageProps {
  imageSrc: string;
  imageWidth: number;
  imageHeight: number;
  imageAlt?: string;
  className?: string;
  blur?: boolean;
  lazyLoad?: boolean;
}

export default function HomeHeaderImage({
  imageSrc,
  imageWidth,
  imageHeight,
  imageAlt = "",
  blur = false,
  className,
  lazyLoad = true,
}: HomeHeaderImageProps) {
  // Adjust the image height to be 1.5 times the height, default on cloudinary is 1024x1024
  imageSrc = imageSrc.replace("f_auto,q_auto", `f_auto,q_auto,h_${Math.round(imageHeight * 1.5)}`);

  return (
    <div
      className={cn(
        styles({
          blur,
        }),
        className
      )}
    >
      <div className="clip-path-header">
        <img
          src={imageSrc}
          alt={imageAlt}
          width={imageWidth}
          height={imageHeight}
          loading={lazyLoad ? "lazy" : "eager"}
          className="bg-ui-grey-200"
        />
      </div>
      <svg className="flt_svg" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <filter id="flt_tag">
            <feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9"
              result="flt_tag"
            />
            <feComposite in="SourceGraphic" in2="flt_tag" operator="atop" />
          </filter>
        </defs>
      </svg>
    </div>
  );
}
