"use client";

import {
  Button,
  ButtonText,
  IconToolsAi,
  TextBody,
  TextHeadline,
  TextTitle,
} from "@sourceful/design-system-v3";
import Link from "next/link";
import env from "../../../lib/utils/env";
import { PackagingImage } from "../../types/types";
import BenefitsSection from "../components/BenefitsSection";
import CallToActionSection from "../components/CallToActionSection";
import FAQSection from "../components/FAQSection";
import HomeHeader from "../components/HomeHeader";
import HowItWorksSection from "../components/HowItWorksSection";
import ImageGrid from "../components/ImageGrid";
import TextGradient from "../components/TextGradient";
import { usePageContext } from "../providers/PageProvider/PageProvider";

const SPRING_URL = env("SPRING_URL");

interface SpringHomeContentProps {
  images: PackagingImage[];
}

export function SpringHomeContent({ images }: Readonly<SpringHomeContentProps>) {
  const { setShowGenerateDialog } = usePageContext();

  return (
    <>
      <HomeHeader images={images} onGenerate={() => setShowGenerateDialog(true)} />
      {/* NOTE: Negative margin this up, need to keep the fixed height on HomeHeader above for positional ease of images */}
      <section className="col-span-full flex flex-col gap-24 -mt-112 relative lg:mt-0 lg:gap-32 lg:pt-136">
        <div className="flex flex-col gap-24 max-w-2xl">
          <TextBody size="kicker" className="w-fit" asChild>
            <p>Packaging inspiration</p>
          </TextBody>
          <TextHeadline size="large" asChild>
            <h2>
              <TextGradient text="Generate packaging ideas" /> inspired by existing designs
            </h2>
          </TextHeadline>
        </div>
        <div className="flex flex-col gap-40 lg:gap-72">
          <TextBody size="small" weight="medium" asChild>
            <p>
              Design product packaging quickly using one of these suggestions or{" "}
              <TextGradient text="create your own from scratch." />
            </p>
          </TextBody>
          <ImageGrid images={images}>
            <Button variant={"outline"} size="md" className="self-end" asChild>
              <Link href={`${SPRING_URL}/all`}>
                <ButtonText>Get more inspiration</ButtonText>
              </Link>
            </Button>
          </ImageGrid>
        </div>
      </section>

      <CallToActionSection>
        <TextTitle size="large" className="text-ui-lightest text-center" asChild>
          <h2>Generate AI packaging ideas for free.</h2>
        </TextTitle>

        <Button
          size="md"
          variant={"secondary"}
          layout={"iconLeading"}
          className=""
          onClick={() => setShowGenerateDialog(true)}
        >
          <IconToolsAi className="size-24" />
          <ButtonText>Create your AI packaging design</ButtonText>
        </Button>
      </CallToActionSection>

      <HowItWorksSection />

      <BenefitsSection className="pt-0 lg:py-64" />

      <div className="col-span-full relative flex flex-col gap-72 mt-[7rem] pb-64 md:gap-104 lg:pt-64 lg:mt-[10rem]">
        <span className="absolute w-[100vw] left-1/2 right-1/2 top-[-3rem] bottom-[-30vh] -ml-[50vw] -mr-[50vw] bg-gradient-br-violet-blue-green-subtle z-[-1] skew-y-2"></span>

        <FAQSection />

        <CallToActionSection>
          <TextTitle size="large" className="text-ui-lightest text-center" asChild>
            <h2>Ready to create your unique packaging?</h2>
          </TextTitle>

          <Button
            size="md"
            variant={"secondary"}
            layout={"iconLeading"}
            className=""
            onClick={() => setShowGenerateDialog(true)}
          >
            <IconToolsAi className="size-24" />
            <ButtonText>Create your AI packaging design</ButtonText>
          </Button>
        </CallToActionSection>
      </div>
    </>
  );
}
