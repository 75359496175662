import {
  Button,
  ButtonText,
  Grid,
  IconToolsAi,
  TextBody,
  TextDisplay,
  TextTitle,
} from "@sourceful/design-system-v3";
import { PackagingImage } from "../../types/types";
import HomeHeaderImage from "./HomeHeaderImage";

interface HomeHeaderProps {
  onGenerate?: () => void;
  images: PackagingImage[];
}

export default function HomeHeader({ onGenerate, images }: Readonly<HomeHeaderProps>) {
  return (
    <div className="col-span-full flex flex-col items-center gap-32 text-ui-grey-950 pt-112 relative h-[700px]">
      <TextBody size="kicker" asChild>
        <p>Sourceful</p>
      </TextBody>

      <TextDisplay size="jumbo" asChild>
        <h1>Spring</h1>
      </TextDisplay>

      <Grid margin={"no"} columns={"12"}>
        <TextTitle
          size="medium"
          className="col-span-12 lg:col-span-8 lg:col-start-3 text-center"
          asChild
        >
          <p>Your best packaging designed by our AI design generator, for free.</p>
        </TextTitle>
      </Grid>

      <Button size="lg" variant="primary" layout="iconLeading" onClick={onGenerate}>
        <IconToolsAi className="size-24" />
        <ButtonText>Create your AI packaging design</ButtonText>
      </Button>

      {images && images.length > 8 && (
        <>
          <HomeHeaderImage
            imageSrc={images[0].src}
            imageWidth={161}
            imageHeight={161}
            className="ml-[-120px] mt-[-290px] size-[100px] lg:ml-[-360px] lg:mt-[-180px] lg:size-[161px]"
            lazyLoad={false}
          />

          <HomeHeaderImage
            imageSrc={images[1].src}
            imageWidth={280}
            imageHeight={280}
            className="ml-[-150px] mt-[110px] size-[74px] lg:ml-[-580px] lg:mt-[195px] lg:size-[280px]"
            lazyLoad={false}
          />

          <HomeHeaderImage
            imageSrc={images[2].src}
            imageWidth={188}
            imageHeight={188}
            className="ml-[170px] mt-[-250px] size-[69px] lg:ml-[290px] lg:mt-[-310px] lg:size-[188px]"
            lazyLoad={false}
          />

          <HomeHeaderImage
            imageSrc={images[3].src}
            imageWidth={290}
            imageHeight={290}
            className="hidden ml-[600px] mt-[-30px] size-[290px] lg:block"
            lazyLoad={false}
          />

          <HomeHeaderImage
            imageSrc={images[4].src}
            imageWidth={169}
            imageHeight={169}
            className="ml-[120px] mt-[130px] size-[123px] lg:ml-[300px] lg:mt-[250px] lg:size-[169px]"
            lazyLoad={false}
          />

          <HomeHeaderImage
            imageSrc={images[5].src}
            imageWidth={107}
            imageHeight={107}
            className="hidden lg:block lg:ml-[-620px] lg:mt-[-350px] lg:size-[107px]"
            blur
          />

          <HomeHeaderImage
            imageSrc={images[6].src}
            imageWidth={93}
            imageHeight={93}
            className="ml-[-300px] mt-[-110px] size-[66px] lg:ml-[-640px] lg:mt-[-50px] lg:size-[93px]"
            blur
          />

          <HomeHeaderImage
            imageSrc={images[7].src}
            imageWidth={62}
            imageHeight={62}
            className="ml-[-50px] mt-[80px] size-[33px] lg:ml-[-120px] lg:mt-[210px] lg:size-[62px]"
            blur
          />

          <HomeHeaderImage
            imageSrc={images[8].src}
            imageWidth={96}
            imageHeight={96}
            className="ml-[270px] mt-[-120px] size-[92px] lg:ml-[610px] lg:mt-[200px] lg:size-[96px]"
            blur
          />
        </>
      )}
    </div>
  );
}
