/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button, ButtonText, Figure, Grid, TextLabel, cn, cva } from "@sourceful/design-system-v3";
import chunk from "lodash/chunk";
import Link from "next/link";
import React from "react";
import env from "../../../lib/utils/env";
import { PackagingImage } from "../../types/types";

const SPRING_URL = env("SPRING_URL");

export interface ImageGridProps {
  images: PackagingImage[];
  children?: React.ReactNode;
}

const imageLinkStyles = cva(
  `flex rounded-md relative 
  focus-visible:focus-ring 
  [&_[data-interactive]]:focus-visible:flex [&_[data-interactive]]:hover:flex`,
  {
    variants: {
      large: {
        true: "col-span-2",
      },
    },
  }
);

const ImageGrid: React.FC<ImageGridProps> = ({ images, children }) => {
  if (!images || images.length === 0) {
    return null;
  }

  const chunks = chunk(images, 5);

  const chunkReminder = chunks.length % 3;
  let shouldHideLastRow = false;

  if (chunkReminder !== 0) {
    shouldHideLastRow = true;
  }

  if (shouldHideLastRow) {
    // pop reminder chunks
    for (let i = 0; i < chunkReminder; i++) {
      chunks.pop();
    }
  } else if (chunks.length > 0 && chunks[chunks.length - 1].length < 5) {
    // Remove the last 3 chunks (or all remaining chunks if less than 3)
    const chunksToRemove = Math.min(3, chunks.length);
    chunks.splice(-chunksToRemove);
  }

  return (
    <div className="col-span-full flex flex-col gap-24">
      <Grid margin={"no"} columns={"12"} className="grid-cols-2 md:grid-cols-12">
        {chunks.map((chunk, chunkindex) => {
          return (
            <div
              key={`chunk-${chunk[0]?.id || ""}-${chunkindex}`}
              className={cn(
                "col-span-full md:col-span-4 lg:col-span-4 grid grid-cols-2 gap-16 content-start md:gap-24 xl:gap-32"
              )}
              data-chunk={chunkindex}
            >
              {chunk.map((image, imageindex) => {
                const isLarge =
                  (imageindex === 0 && chunkindex % 2 === 0) ||
                  (imageindex === 4 && chunkindex % 2 !== 0);

                const imageSize = Math.round((isLarge ? 448 : 208) * 1.5);

                // Adjust the image height to be 1.5 times the height, default on cloudinary is 1024x1024
                const imageSrc = image.src.replace("f_auto,q_auto", `f_auto,q_auto,h_${imageSize}`);
                return (
                  <Link
                    key={image.id}
                    href={`${SPRING_URL}/generations/${image.req_id}`}
                    className={cn(
                      imageLinkStyles({
                        large: isLarge,
                      })
                    )}
                  >
                    <Figure
                      state={"loaded"}
                      aspectRatio={"oneOne"}
                      contentLayout={"contained"}
                      className="rounded-md"
                    >
                      <img
                        className="rounded-md w-full"
                        width="400"
                        height="400"
                        src={imageSrc}
                        alt={
                          image.alt_text ||
                          `AI-Generated${image.product_brief ? " for " + image.product_brief : ""}`
                        }
                        data-size={imageSize}
                      />

                      <span
                        data-interactive=""
                        className="dark hidden absolute inset-0 flex-col gap-24 justify-center items-center p-16 bg-ui-alpha-black-60 rounded-md"
                      >
                        <TextLabel
                          size="large"
                          className="text-ui-lightest text-center line-clamp-2"
                        >
                          {image.product_brief}
                        </TextLabel>
                        <Button variant={"secondary"} size="md" asChild>
                          <span>
                            <ButtonText>View designs</ButtonText>
                          </span>
                        </Button>
                      </span>
                    </Figure>
                  </Link>
                );
              })}
            </div>
          );
        })}
      </Grid>
      {children}
    </div>
  );
};

export default ImageGrid;
