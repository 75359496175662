import { Grid, cn } from "@sourceful/design-system-v3";

interface CardProps {
  className?: string;
  gridClassName?: string;
  children?: React.ReactNode;
}

export function Card({ className, gridClassName, children }: Readonly<CardProps>) {
  return (
    <article
      className={cn(
        "flex items-center bg-ui-grey-50 rounded-md overflow-hidden md:h-[480px]",
        className
      )}
    >
      <Grid margin={"no"} className={cn("grid-cols-1 h-full md:grid-cols-2", gridClassName)}>
        {children}
      </Grid>
    </article>
  );
}

interface CardContentProps {
  className?: string;
  children?: React.ReactNode;
}

export function CardContent({ className, children }: Readonly<CardContentProps>) {
  return (
    <div
      className={cn(
        "flex flex-col self-center gap-24 pt-24 px-24 md:pt-0 md:px-48 lg:px-80",
        className
      )}
    >
      {children}
    </div>
  );
}

interface CardAssetProps {
  className?: string;
  children?: React.ReactNode;
}

export function CardAsset({ className, children }: Readonly<CardAssetProps>) {
  return <div className={cn("relative h-[260px] md:h-auto", className)}>{children}</div>;
}
