import { cn } from "@sourceful/design-system-v3";

export interface TextGradientProps {
  text: string;
  className?: string;
  variant?: "violet-blue-green" | "violet-blue" | "blue-green";
}

export default function TextGradient({
  text,
  className,
  variant = "violet-blue",
}: Readonly<TextGradientProps>) {
  return (
    <span
      className={cn(
        { "bg-gradient-br-violet-blue-green": variant === "violet-blue-green" },
        { "bg-gradient-br-violet-blue": variant === "violet-blue" },
        { "bg-gradient-br-blue-green": variant === "blue-green" },
        "text-transparent bg-clip-text",
        className
      )}
    >
      {text}
    </span>
  );
}
