import {
  IconNavigationPeopleGradientToBrBlueGreen,
  IconNavigationShareGradientToBrBlueGreen,
  IconSystemFlashGradientToBrBlueGreen,
  TextBody,
  TextHeadline,
  cn,
} from "@sourceful/design-system-v3";
import Image from "next/image";
import { Card, CardAsset, CardContent } from "./Card";
import { CardAiAndSustainability } from "./CardAiAndSustainability";
import TextGradient from "./TextGradient";

interface BenefitsSectionProps {
  className?: string;
}

export default function BenefitsSection({ className }: Readonly<BenefitsSectionProps>) {
  return (
    <section
      className={cn(
        "col-span-full pt-40 lg:py-104 flex flex-col gap-40 lg:items-center lg:gap-72",
        className
      )}
    >
      <div className="flex flex-col gap-24 max-w-2xl lg:items-center lg:text-center">
        <TextBody size="kicker" className="w-fit" asChild>
          <p>Benefits</p>
        </TextBody>
        <TextHeadline size="large" asChild>
          <h2>
            Helping you go to market{" "}
            <TextGradient text="faster" variant="violet-blue-green" className="block lg:inline" />
          </h2>
        </TextHeadline>
      </div>
      <div className="max-w-6xl flex flex-col gap-20 md:gap-40">
        <Card>
          <CardContent className="md:order-2">
            <IconSystemFlashGradientToBrBlueGreen className="size-32 md:size-40" />
            <TextHeadline size="medium" asChild>
              <h3>Instantly visualise packaging ideas</h3>
            </TextHeadline>
            <TextBody size="medium" asChild>
              <p>
                No more waiting for expensive, physical samples to arrive, instantly get an idea of
                how your packaging will look with the power of AI.
              </p>
            </TextBody>
          </CardContent>
          <CardAsset>
            <Image
              alt=""
              src="/images/instantly-visualise-packaging-ideas.webp"
              width={800}
              height={746}
              className="object-contain w-[120%] h-[120%] absolute top:0 left-1/2 -translate-x-1/2 md:top-1/2 md:right-0 transform md:-translate-y-1/2 max-w-none"
              loading="lazy"
              unoptimized
            />
          </CardAsset>
        </Card>

        <Card>
          <CardContent>
            <IconNavigationShareGradientToBrBlueGreen className="size-32 md:size-40" />
            <TextHeadline size="medium" asChild>
              <h3>Test your market</h3>
            </TextHeadline>
            <TextBody size="medium" asChild>
              <p>
                Share your packaging visualisation with your loyal customers and get their feedback
                on which design they like best.
              </p>
            </TextBody>
          </CardContent>
          <CardAsset className="md:order-1">
            <Image
              alt=""
              src="/images/test-your-market.webp"
              width={800}
              height={746}
              className="object-contain w-[120%] h-[120%] absolute top:0 left-1/2 -translate-x-1/2 md:top-1/2 md:right-0 transform md:-translate-y-1/2 max-w-none"
              loading="lazy"
              unoptimized
            />
          </CardAsset>
        </Card>

        <Card>
          <CardContent className="md:order-2">
            <IconNavigationPeopleGradientToBrBlueGreen className="size-32 md:size-40" />
            <TextHeadline size="medium" asChild>
              <h3>Accelerate collaboration</h3>
            </TextHeadline>
            <TextBody size="medium" asChild>
              <p>
                Share your AI packaging designs with your team to get sign off on your new packaging
                designs quickly.
              </p>
            </TextBody>
          </CardContent>
          <CardAsset>
            <Image
              alt=""
              src="/images/share-your-designs.webp"
              width={800}
              height={746}
              className="object-contain w-[120%] h-[120%] absolute top:0 left-1/2 -translate-x-1/2 md:top-1/2 md:right-0 transform md:-translate-y-1/2 max-w-none"
              loading="lazy"
              unoptimized
            />
          </CardAsset>
        </Card>

        <CardAiAndSustainability />
      </div>
    </section>
  );
}
