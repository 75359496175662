"use client";

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  IconProductsStickers,
  IconProductsStickersGradientToBrBlueGreen,
  IconToolsAi,
  IconToolsAiGradientToBrBlueGreen,
  IconToolsPalettePicker,
  IconToolsPalettePickerGradientToBrBlueGreen,
  TextBody,
  TextHeadline,
  TextTitle,
  cn,
  cva,
} from "@sourceful/design-system-v3";

import Image from "next/image";
import { useState } from "react";
import TextGradient from "./TextGradient";

const accordionItemStyles = cva(`bg-ui-lightest rounded-none border-0 relative`, {
  variants: {
    open: {
      true: `[&[data-state=open]]:text-ui-grey-950 
        after:block after:w-4 after:absolute after:left-0 after:top-0 after:bottom-0 after:bg-gradient-b-violet-blue after:rounded-md z-10`,
      false: "[&[data-state=closed]]:opacity-50",
    },
  },
});

const imageStyles = cva(`object-contain max-h-full`, {
  variants: {
    active: {
      true: "inline",
      false: "hidden",
    },
  },
});

export default function HowItWorksSection() {
  const [activeAccordion, setActiveAccordion] = useState("idea-1");

  return (
    <section className="col-span-full relative flex flex-col gap-40 my-[7rem] lg:gap-72 lg:mt-[10rem]">
      <span className="absolute w-[100vw] left-1/2 right-1/2 top-[-3rem] bottom-[-3rem] -ml-[50vw] -mr-[50vw] bg-gradient-br-violet-blue-green-subtle z-[-1] skew-y-2"></span>
      <div className="flex flex-col gap-24">
        <TextBody size="kicker" className="w-fit" asChild>
          <p>How it works</p>
        </TextBody>
        <TextHeadline size="large" asChild>
          <h2>
            AI packaging ideas in minutes{" "}
            <TextGradient variant="violet-blue-green" text="for free!" className="block" />
          </h2>
        </TextHeadline>
      </div>
      <div className="flex flex-col-reverse md:flex-row items-center bg-ui-lightest p-40 gap-40 rounded-md min-h-[592px]">
        <Accordion
          type="single"
          defaultValue={activeAccordion}
          className={`relative basis-0 border-none md:gap-20 md:basis-1/2
          after:block after:w-4 after:absolute after:left-0 after:top-0 after:bottom-0 after:bg-ui-grey-100 after:rounded-md`}
          onValueChange={value => setActiveAccordion(value)}
        >
          <AccordionItem
            value="idea-1"
            className={cn(accordionItemStyles({ open: activeAccordion === "idea-1" }))}
          >
            <AccordionTrigger wrapperElement={"h3"} className="p-16 pl-20">
              <span className="flex flex-row items-center gap-16">
                {activeAccordion === "idea-1" ? (
                  <IconProductsStickersGradientToBrBlueGreen className="size-32 transform-none" />
                ) : (
                  <IconProductsStickers className="size-32 transform-none" />
                )}
                <TextTitle size="small" className="text-start">
                  Describe your product
                </TextTitle>
              </span>
            </AccordionTrigger>
            <AccordionContent>
              <TextBody size="small" className="p-16 pl-20 pb- pt-0" asChild>
                <div className="flex flex-col space-y-16">
                  <p>
                    Get started quickly, tell us the product you need packaging designed for and
                    your brand name.
                  </p>
                </div>
              </TextBody>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem
            value="idea-2"
            className={cn(accordionItemStyles({ open: activeAccordion === "idea-2" }))}
          >
            <AccordionTrigger wrapperElement={"h3"} className="p-16">
              <span className="flex flex-row items-center gap-16">
                {activeAccordion === "idea-2" ? (
                  <IconToolsPalettePickerGradientToBrBlueGreen className="size-32 transform-none" />
                ) : (
                  <IconToolsPalettePicker className="size-32 transform-none" />
                )}
                <TextTitle size="small" className="text-start">
                  Select your colours and style
                </TextTitle>
              </span>
            </AccordionTrigger>
            <AccordionContent>
              <TextBody size="small" className="p-16 pt-0" asChild>
                <div className="flex flex-col space-y-16">
                  <p>
                    Make sure your packaging reflects your branding by selecting your brand colours
                    and aesthetic.
                  </p>
                </div>
              </TextBody>
            </AccordionContent>
          </AccordionItem>
          <AccordionItem
            value="idea-3"
            className={cn(accordionItemStyles({ open: activeAccordion === "idea-3" }))}
          >
            <AccordionTrigger wrapperElement={"h3"} className="p-16">
              <span className="flex flex-row items-center gap-16">
                {activeAccordion === "idea-3" ? (
                  <IconToolsAiGradientToBrBlueGreen className="size-32 transform-none" />
                ) : (
                  <IconToolsAi className="size-32 transform-none" />
                )}
                <TextTitle size="small" className="text-start">
                  Generate your designs
                </TextTitle>
              </span>
            </AccordionTrigger>
            <AccordionContent>
              <TextBody size="small" className="p-16 pt-0" asChild>
                <div className="flex flex-col space-y-16">
                  <p>
                    Receive an email within minutes linking to your 9 new AI generated packaging
                    designs for your brand.
                  </p>
                </div>
              </TextBody>
            </AccordionContent>
          </AccordionItem>
        </Accordion>

        <div className="h-[512px] basis-1/2 flex place-content-center">
          <Image
            src="/images/describe-your-product.avif"
            alt=""
            className={cn(imageStyles({ active: activeAccordion === "idea-1" }))}
            width={683}
            height={512}
            unoptimized
          />
          <Image
            src="/images/add-your-brand.avif"
            alt=""
            className={cn(imageStyles({ active: activeAccordion === "idea-2" }))}
            width={683}
            height={512}
            unoptimized
          />
          <Image
            src="/images/generate-your-designs.avif"
            alt=""
            className={cn(imageStyles({ active: activeAccordion === "idea-3" }))}
            width={683}
            height={512}
            unoptimized
          />
        </div>
      </div>
    </section>
  );
}
