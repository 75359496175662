import {
  Button,
  ButtonText,
  IconToolsAi,
  TextBody,
  TextHeadline,
} from "@sourceful/design-system-v3";
import Image from "next/image";
import { Card, CardContent } from "./Card";

export function CardAiAndSustainability() {
  return (
    <Card className="relative" gridClassName="md:grid-cols-1">
      <Image
        alt=""
        src="/images/ai-bg.webp"
        width={1170}
        height={480}
        className="object-cover absolute top-0 left-0 w-full h-full z-[0]"
        loading="lazy"
        unoptimized
      />
      <CardContent className="self-start pb-24 relative z-[1] md:pb-0 md:self-center">
        <IconToolsAi className="size-32 md:size-40" />
        <TextHeadline size="medium" asChild>
          <h3>AI and Sustainability</h3>
        </TextHeadline>
        <TextBody size="medium" asChild>
          <p>
            There's no doubt that generative AI is increasingly making its way into more and more
            areas of everyday life, including packaging. But what impact has AI had on our planet?
          </p>
        </TextBody>
        <Button
          variant="outline"
          layout={"textOnly"}
          size="lg"
          className="w-fit whitespace-nowrap md:mx-0"
          asChild
        >
          <a href="https://www.sourceful.com/blog/impact-report-2024">
            <ButtonText>
              Learn more<span className="sr-only"> about AI and Sustainability</span>
            </ButtonText>
          </a>
        </Button>
      </CardContent>
    </Card>
  );
}
